<template>
    <el-row>
        <el-col :span="24" class="__header_col">
            <Header></Header>
        </el-col>
        <el-col :span="24" class="__body_col">
            <router-view />
        </el-col>
        <!-- <el-header>
            <el-menu class="el-menu-demo" mode="horizontal" :default-active="'inTrack'">
                <el-menu-item v-for="(item, index) in menus" :key="index" :index="item.name" @click="clickMenu(item.name)">
                    <span slot="title">{{item.label}}</span>
                </el-menu-item>
                <div class="info" style="float:right;display:inline-flex">
                        <h4>{{$root.employee.name}}</h4>&nbsp;
                        <h5>: {{$root.employee.outlet}}</h5>&nbsp;&nbsp;
                    
                </div>
            </el-menu>
        </el-header> -->
        <!-- <el-container class="main-container">
            <el-aside>
                <div>
                    <img src="@/assets/kfc.png" alt="" @click="clickMenu('Самбар')">
                </div>
                <el-menu>
                    <div style="display:inline-flex">
                        &nbsp;&nbsp;
                        <h4>{{$root.employee.name}}</h4>&nbsp;
                        <h5> : {{$root.employee.outlet}}</h5>
                    </div>
                    <el-menu-item v-for="(item, index) in menus" :key="index" :index="item.name" @click="clickMenu(item.name)">
                        <span slot="title">{{item.label}}</span>
                    </el-menu-item>
                </el-menu>
            </el-aside>
            <el-main>
                <router-view></router-view>
            </el-main>
        </el-container> -->
    </el-row>
</template>

<script>
import Header from '@/components/header/header'
export default {
    created () {
    },
    components: {
      Header
    },
    data () {
      return {
        menus: [
          {
              name: 'inTrack',
              label: 'Хүргэж өгөх',
              icon: 'fas fa-list-alt menuicon'
          },
          {
              name: 'exit',
              label: 'Гарах',
              icon: 'fas fa-list-alt menuicon'
          }
        ],
        orderList: [
        ],
      }
    },
    methods: {
        
    }
}
</script>

<style>
.info {
    display:inline-flex;
}
</style>