// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  'aws_project_region': 'ap-southeast-1',
  'aws_cognito_identity_pool_id': 'ap-southeast-1:bee242ab-52f6-4eb0-b110-af79ec63d4ad',
  // 'aws_cognito_identity_pool_id': 'ap-southeast-1:66fe0205-c2b5-4685-a193-9cc9484533fb',
  'aws_cognito_region': 'ap-southeast-1',
  'aws_user_pools_id': 'ap-southeast-1_xSDsmT6iV',
  // 'aws_user_pools_id': 'ap-southeast-1_1HaGleylw',
  'aws_user_pools_web_client_id': '400f9sdboaplmp9im2ge1nqr7a',
  // 'aws_user_pools_web_client_id': '5re8ivdqto71ua690hokrmkbv4',
  'oauth': {}
}
export default awsmobile
