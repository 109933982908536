import Vue from 'vue'

Vue.filter('formatDate', function (value) {
  if (!value) return ''
  return Vue.prototype.$moment(String(value)).format('YYYY-MM-DD')
})

Vue.filter('formatDateTime', function (value) {
  if (!value) return ''
  return Vue.prototype.$moment(String(value)).format('YYYY-MM-DD HH:mm:ss')
})

Vue.filter('fromNow', function (value) {
  if (!value) return ''
  return Vue.prototype.$moment(value).fromNow()
})

Vue.filter('formatTugrug', function (value) {
  if (!value) return ''
  return String(parseInt(value)).replace(/\d(?=(\d{3})+$)/g, '$&.') + '₮'
})
