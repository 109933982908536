import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import mainLayout from '../pages/layout/mainLayout.vue'
import NotFound from '../pages/404.vue'
import Login from '../pages/login.vue'
import order from '../pages/order.vue'

Vue.use(VueRouter)

export const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/main',
    },
    {
      path: '/login',
      component: Login
    },
    {
      path: '*',
      component: NotFound
    },
    {
      path: '/main',
      component: mainLayout,
      redirect: '/order',
      children: [
        {
          path: '/order',
          name: 'order',
          component: order
        }
      ]
    }
  ]
})

export default router
