import config from '@/config/index.js'
export const mainRequests = {
  getDriver,
  postDriver,
  getManager,
  postManager,
  getOrder,
  postOrder
}

function handleResponse (response) {
  return response
    .text()
    .then(text => {
      const data = text && JSON.parse(text)
      if (!response.ok) {
        if (response.status === 401) {
          //   logout()npm run serve
          location.reload(true)
        }

        const error = (data && data.message) || response.statusText
        return Promise.reject(error)
      }

      return data
    })
    .catch(err => {
      return Promise.reject(err)
    })
}
async function postDriver (url, payload) {
  const promise = new Promise((resolve, reject) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    }
    fetch(`${config.DRIVER_BACKEND_URL}` + url, requestOptions)
      .then(handleResponse)
      .then(data => {
        resolve(data)
      })
      .catch(error => {
        reject(error)
      })
  })
  const result = await promise
  return result
}

async function getDriver (url) {
  const promise = new Promise((resolve, reject) => {
    // const requestOptions = {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json' }
    // }
    fetch(`${config.DRIVER_BACKEND_URL}` + url)
      .then(handleResponse)
      .then(data => {
        resolve(data)
      })
      .catch(error => {
        // Message({
        //   message: 'Хүсэлт явахад алдаа гарлаа...!',
        //   duration: 2500,
        //   type: 'error',
        //   showClose: 1
        // })
        alert(this.locale == 'mn' ? 'Хүсэлт явахад алдаа гарлаа.': 'Request Failed')
        reject(error)
      })
  })
  const result = await promise
  return result
}

async function postManager (url, payload) {
  const promise = new Promise((resolve, reject) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    }
    fetch(`${config.MANAGER_BACKEND_URL}` + url, requestOptions)
      .then(handleResponse)
      .then(data => {
        resolve(data)
      })
      .catch(error => {
        reject(error)
      })
  })
  const result = await promise
  return result
}

async function getManager (url) {
  const promise = new Promise((resolve, reject) => {
    // const requestOptions = {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json' }
    // }
    fetch(`${config.MANAGER_BACKEND_URL}` + url)
      .then(handleResponse)
      .then(data => {
        resolve(data)
      })
      .catch(error => {
        // Message({
        //   message: 'Хүсэлт явахад алдаа гарлаа...!',
        //   duration: 2500,
        //   type: 'error',
        //   showClose: 1
        // })
        alert(this.locale == 'mn' ? 'Хүсэлт явахад алдаа гарлаа.': 'Request Failed')
        reject(error)
      })
  })
  const result = await promise
  return result
}

async function postOrder (url, payload) {
  const promise = new Promise((resolve, reject) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    }
    fetch(`${config.ORDER_BACKEND_URL}` + url, requestOptions)
      .then(handleResponse)
      .then(data => {
        resolve(data)
      })
      .catch(error => {
        reject(error)
      })
  })
  const result = await promise
  return result
}

async function getOrder (url) {
  const promise = new Promise((resolve, reject) => {
    // const requestOptions = {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json' }
    // }
    fetch(`${config.ORDER_BACKEND_URL}` + url)
      .then(handleResponse)
      .then(data => {
        resolve(data)
      })
      .catch(error => {
        // Message({
        //   message: 'Хүсэлт явахад алдаа гарлаа...!',
        //   duration: 2500,
        //   type: 'error',
        //   showClose: 1
        // })
        alert(this.locale == 'mn' ? 'Хүсэлт явахад алдаа гарлаа.': 'Request Failed')
        reject(error)
      })
  })
  const result = await promise
  return result
}

