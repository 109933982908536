<template>
  <el-breadcrumb class="__breadcrump_class p-t-15 p-b-10" separator-class="el-icon-arrow-right">
    <div class="__breadcrumb_item_layout">
      <el-breadcrumb-item class="__breadcrumb_item" :to="{ path: '/' }">
        <i class="el-icon-s-home"></i>
      </el-breadcrumb-item>
      <el-breadcrumb-item v-for="item in items" :key="item">{{item}}</el-breadcrumb-item>
      <!-- <el-breadcrumb-item v-if="child" >{{child}}</el-breadcrumb-item> -->
    </div>
</el-breadcrumb>
</template>

<script>
export default {
  props: ['items']
}
</script>

<style>

</style>
