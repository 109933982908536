<template>
  <el-row type="flex" class="__header">
    <el-col :offset="1" :span="2" class="__header_logo">
      <img src="@/assets/images/__logo.png" width="40" height="">
      </el-col>
      <el-col class="__header_user_info" :span="21">
          <el-row class="avatar_and_button">
              <el-col :span="6">
              <el-avatar class="m-t-5" :size="30"
                  icon="el-icon-user-solid">
              </el-avatar>
              </el-col>
              <el-col :span="8" class="m-t-10">
              <span class="__header_avatar">{{$root.employee.name}}</span>
              </el-col>
              <el-col :span="6">
              <el-dropdown @command="handleCommand">
                  <span class="el-dropdown-link">
                  <i class="el-icon-arrow-down el-icon--right m-t-10 m-l-50"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="logout">Гарах</el-dropdown-item>
                  </el-dropdown-menu>
              </el-dropdown>
              </el-col>
          </el-row>
      </el-col>
  </el-row>
</template>

<script>
export default {
  created () {
    if (window.localStorage.user == null) {
        this.$router.push('/login')
    } else {
        this.$root.employee = JSON.parse(window.localStorage.user)
        this.nowTime = new Date()
    }
    console.log(this.$root.employee)
  },
  data() {
    return {

    }
  },
  methods: {
    handleCommand (command) {
      if (command === 'logout') {
          this.logout()
      }
    },
    logout () {
      window.localStorage.removeItem('user');
      this.$router.push('/login')
    },
  }
}
</script>

<style>

</style>