import { mainRequests } from '@/helpers/custom.js'
// import axios from 'axios'
export default {
  getDriverbyOutletNameAndDriverPin (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests.postDriver('/driverApp/getDriverbyOutletNameAndDriverPin', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
}