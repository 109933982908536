<template>
<div class="__login">
    <el-col :xs="22" :sm="20" :md="8" class="__wrap">
      <div class="__center">
        <img src="@/assets/images/__logo.png" height="150"/>
      </div>
      <el-form :model="loginForm" :rules="rules" ref="loginForm" label-width="120px" label-position="top">
        <el-form-item prop="outlet_name">
          <el-select v-model="loginForm.outlet_name" placeholder="Салбараа сонгоно уу" style="width:100%;">
            <el-option v-for="(outlet, index) in this.outlets" :key="index" :label="outlet.name_mn" :value="outlet.outletname"> 
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="driver_pin">
          <el-input placeholder="Нууц үг" v-model="loginForm.driver_pin" show-password></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="__btn" @click="submitForm('loginForm')" type="primary" :loading="loading">
            Нэвтрэх
          </el-button>
        </el-form-item>
      </el-form>
    </el-col>
  </div>
</template>

<script>
import driverServices from '@/services/driverServices.js'
import managerServices from '@/services/managerServices.js'
  export default {
    data() {
      return {
        outlets: [],
        loginForm: {
          outlet_name: '',
          driver_pin: ''
        },
        loading: false,
        rules: {
          outlet_name: [
            { required: true, message: 'Салбар сонгоно уу', trigger: 'blur' }
          ],
          driver_pin: [
            { required: true, message: 'Пин код оруулна уу', trigger: 'blur' }
          ]
        }
      };
    },
    created () {
      this.getOutlets ()
    },
    methods: {
      getOutlets () {
        managerServices.getOutlets().then(response => {
          if (response.success) {
            this.outlets = response.result
          } else {
            this.$notify({
              title: 'Амжилтгүй',
              message: response.message,
              type: 'error'
            })
          }
        }).catch(error => {
          this.$notify({
            title: 'Амжилтгүй',
            message: error.message,
            type: 'error'
          })
        })
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.loading = true
            // services.getDriverbyPin()
            driverServices.getDriverbyOutletNameAndDriverPin(this.loginForm).then(response => {
              if (response.success) {
                window.localStorage.setItem('user', JSON.stringify(response.result))
                  this.$notify({
                      title: 'Амжилттай',
                      message: 'Амжилттай нэвтэрлээ',
                      type: 'success'
                    })
                    this.loading = false;
                  this.$router.push('/')
              } else {
                this.$notify({
                  title: 'Амжилтгүй',
                  message: response.message,
                  type: 'error'
                })
                this.loading = false;
              }
            }).catch(error => {
              console.log(error)
              this.$notify({
                title: 'Амжилтгүй',
                message: error.message,
                type: 'error'
              })
              this.loading = false;
            })
          } else {
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      }
    }
  }
</script>

<style>
</style>