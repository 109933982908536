import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// eslint-disable-next-line no-unused-vars
import filters from './filters'
import moment from './plugins/moment.js'
import Amplify from 'aws-amplify'
import awsconfig from './aws-exports'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import './assets/scss/theme/index.css'
import './assets/scss/main.scss'
import mn from 'element-ui/lib/locale/lang/mn'
import locale from 'element-ui/lib/locale'

Vue.filter('formatCurrency', function (value) {
  return new Intl.NumberFormat().format(value) + '₮'
})
Vue.use(ElementUI)
locale.use(mn)
Amplify.configure(awsconfig)

Vue.use(Amplify)
Vue.config.productionTip = false
Vue.use(moment)

new Vue({
  router,
  store,
  data: {
    employee: {},
    outlets: [],
  },
  render: h => h(App)
}).$mount('#app')
