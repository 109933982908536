<template>
<el-col :span="24" class="__main_container">
    <BreadCrumb :items="['Жолооч','Захиалга']"></BreadCrumb>
    <div class="__content_container">
      <div class="__title_row">
        <div class="__title">
          <span class="__title_name">Захиалга</span>
          <!-- {{brandList}} -->
        </div>
        <div class="__right_button">
            <el-button type="primary" size="mini" icon="el-icon-refresh" @click="getOrders($root.employee.name)">Сэргээх</el-button>
        </div>
      </div>
      <div class="__content">
        <el-card class="box-card" :body-style="{ padding: '10px' }" v-loading="loading">
          <el-card style="margin-bottom: 10px" v-if="dashboard">
            <div slot="header">
              <span>Өнөөдөр</span>
              <span style="float: right">Салбар дотроо | Нийт хугацаа</span>
            </div>
            <el-tag>{{dashboard.trans}}ш</el-tag>
            <el-tag>{{dashboard.total | formatCurrency}}</el-tag>
            <el-tag style="float: right">{{dashboard.atDelAvgTime}}</el-tag>
            <el-tag style="float: right">{{dashboard.inStoreAvgTime}}</el-tag>
          </el-card>
          <el-card shadow="never" style="margin-right:10px; border-color:#2d3e4e" v-if="orderList.length === 0">
            <el-row :gutter="20">
              <el-col :span="3">
                <el-tag>
                  <i class="el-icon-warning-outline"></i>
                </el-tag>
              </el-col>
              <el-col :span="18">
                <el-col :span="24">
                  <span style="font-size:12px;color: rgb(255 34 34);">Хүргэж өгөх захиалга байхгүй байна</span>
                </el-col>
              </el-col>
            </el-row>
          </el-card>
          <el-card v-for="(order, index) in orderList" :key="index" shadow="never" style="margin-right:10px; border-color:#2d3e4e">
              <el-row :gutter="20">
                <el-col :span="3">
                  <el-tag>
                    <i class="el-icon-time"></i>
                  </el-tag>
                </el-col>
                <el-col :span="18">
                  <el-col :span="12">
                    <span class="order_number">Дугаар#: {{order.order_number}}</span><br>
                    <span class="order_number">Суваг: {{order.channel}}</span><br>
                  </el-col>
                  <el-col :span="12">
                    <span class="order_payment" >Утас: {{order.phone}}</span><br>
                    <span class="order_payment" >Төлбөр: {{order.pay_total | formatTugrug}}</span><br>
                    <span style="font-size:12px;color: #2283ff;">{{order.statusText}}</span>
                  </el-col>
                </el-col>
                <el-col :span="3">
                  <el-button v-if="order.is_paid === 1" style="position: absolute; right: 0;" type="danger" icon="el-icon-finished" circle size="mini" @click="showInfo(order)"></el-button>
                  <el-button v-else style="position: absolute; right: 0;" type="primary" icon="el-icon-s-finance" circle size="mini" @click="showInfo(order)"></el-button>
                </el-col>
                <el-col :span="24">
                  <span style="font-size:12px;color: #2283ff;"> Огноо: {{order.created_at | formatDateTime}}</span><br>
                  <span class="order_address">Хаяг: {{order.addr_bair  + " байр " +  order.addr_haalga + "тоот"}}</span><br>
                  <span class="order_address">Орцны код: {{order.addr_orts }}</span><br>
                  <span class="order_address">Нэмэлт мэдээлэл: {{order.addr_note }}</span><br>
                </el-col>
              </el-row>
            </el-card>
        </el-card>
        <el-dialog :visible.sync="showInfoDialog" :before-close="handleClose" width="90%">
          <el-row>
            <el-col :span="24">
              <el-card shadow="never" style="margin-right:10px; border-color:#2d3e4e">
                <el-row :gutter="20">
                  <el-col :span="3">
                    <el-tag effect="dark">
                      <i class="el-icon-time"></i>
                    </el-tag>
                  </el-col>
                  <el-col :span="21">
                    <el-col :span="12">
                      <span class="order_number">Дугаар#: {{orderData.order_number}}</span><br>
                      <span class="order_number">Суваг: {{orderData.channel}}</span><br>
                    </el-col>
                    <el-col :span="12">
                      <span class="order_payment" >Утас: {{orderData.phone}}</span><br>
                      <span class="order_payment" >Төлбөр: {{orderData.pay_total | formatTugrug}}</span><br>
                      <span style="font-size:12px;color: #2283ff;">{{orderData.statusText}}</span>
                    </el-col>
                  </el-col>
                  <el-col :span="24">
                    <span style="font-size:12px;color: #2283ff;"> Огноо: {{orderData.created_at | formatDateTime}}</span><br>
                    <span class="order_address">Хаяг: {{orderData.addr_bair  + " байр " +  orderData.addr_haalga + "тоот"}}</span><br>
                    <span class="order_address">Орцны код: {{orderData.addr_orts }}</span><br>
                    <span class="order_address">Нэмэлт мэдээлэл: {{orderData.addr_note }}</span><br>
                  </el-col>
                </el-row>
                <el-row class="__payment_type" v-for="(payType, index) in orderData.pay" :key="index">
                  <el-col :span="6">{{payType.name}}</el-col>
                  <el-col :span="18">
                    <el-input v-model="payType.value" :step="100" :min="0" type='number'  @input="calculate(payType, index)" style="width: 100%;">{{payType.value}}</el-input>
                  </el-col>
                </el-row>
                <el-row class="__payment_type">
                  <el-col :span="16">
                    <el-button type="primary" @click="submitForm()">Төлөлт бүртгэх</el-button>
                  </el-col>
                  <el-col :span="8">
                    <el-button @click="handleClose">Буцах</el-button>
                  </el-col>
                </el-row>
              </el-card>
            </el-col>
          </el-row>
        </el-dialog>
      </div>
    </div>
  </el-col>
</template>

<script>
import orderServices from '@/services/orderServices.js'
import managerService from '@/services/managerServices.js'
import BreadCrumb from '@/components/breadcrump/breadcrump'
export default {
  components: {
      BreadCrumb
  },
  data () {
    return {
      loading: false,
      nowTime: '',
      showInfoDialog: false,
      selectedPayments:[],
      value: 0,
      orderData: {
          pay: []
      },
      dashboard: null,
      paymentTypes: [
        {
          code: "cash",
          name: 'Бэлэн',
          value: 0,
        },
        {
          code: "credit_card",
          name: 'Кaрт',
          value: 0,
        },
        {
          code: "tb_coupon",
          name: 'Купон',
          value: 0,
        },
        {
          code: "dans",
          name: 'Дансаар',
          value: 0,
        },
        {
          code: "online",
          name: 'Онлайн',
          value: 0
        }
      ],
      selectedPayment: {},
      orderList: []
    }
  },
  created () {
      this.getOrders(this.$root.employee.name)
  },
  watch: {
    orderData: function () {
      let sum = 0
      this.orderData.pay.forEach(element => {
          sum += element.value
      })
      console.log(sum, 'niilber')
      console.log(this.orderData.payTotal, 'payTotal')
    }
  },
  methods: {
    getOrders (driver) {
      let payload = {
        driver_name: driver
      }
      this.loading = true;
      orderServices.getOrdersByDriverName(payload).then(response => {
        if (response.success) {
          this.orderList = response.result
          this.loading = false
        }
      })
      managerService.getDashboard(payload).then(response => {
        if (response.success) {
          this.dashboard = response.result
        }
      })
    },
    showInfo(selectData) {
      this.orderData = selectData
      if (selectData.is_paid !== 1) {
        this.orderData.pay = []
        this.paymentTypes.forEach(element => {
            this.orderData.pay.push(element)
        });
        this.selectedPayment = this.paymentTypes[0];
        this.selectedPayment.value = selectData.pay_total;
        this.orderData.pay[0].value = selectData.pay_total
        this.showInfoDialog = true
      } else {
        this.$confirm('Захиалгыг дуусгах уу?', 'Асуумж', {
          confirmButtonText: 'Тийм',
          cancelButtonText: 'Үгүй',
          type: 'warning',
          center: true
        }).then(() => {
          orderServices.successOrder(this.orderData).then(response => {
            if (response.success) {
              this.$message({
                  type: 'success',
                  message: 'Захиалга амжилттай дуусгалаа'
              });
              this.showInfoDialog = false;
              this.getOrders(this.$root.employee.name)
            } else {
              this.$message({
                  type: 'error',
                  message: 'Захиалга дуусгахад алдаа гарлаа'
              });  
            }
          }).catch(() => {
            this.$message({
                type: 'info',
                message: 'Захиалга дуусгахыг зогсоолоо'
            });          
          })
        }).catch(() => {
            this.$message({
                type: 'info',
                message: 'Захиалга дуусгахыг зогсоолоо'
            });          
        });
      }
        
    },
    selectPayment () {
      alert('as');
    },
    handleClose () {
      this.orderData = {}
      this.showInfoDialog = false
    },
    calculate(selectData, index) {
      console.log()(selectData, index)
      let sum = 0
      for (let i=0;i<this.orderData.pay.length;i++) {
        sum += parseInt(this.orderData.pay[i].value)
      }
      if (sum > this.orderData.payTotal) {
        for (let i=0;i<this.orderData.pay.length;i++)
          if (i !=index && this.orderData.pay[i].value >= 100) {
            this.orderData.pay[i].value -= 100
            break
          }
      }
    },
    submitForm () {
      let sum = 0
      for (let i=0;i<this.orderData.pay.length;i++) {
        sum += parseInt(this.orderData.pay[i].value)
      }
      if (sum == this.orderData.pay_total) {
        orderServices.successOrder(this.orderData).then(response => {
          if (response.success) {
            this.$message({
                type: 'success',
                message: 'Захиалга амжилттай дуусгалаа'
            });
            this.showInfoDialog = false;
            this.getOrders(this.$root.employee.name)
          } else {
            this.$message({
                type: 'error',
                message: 'Захиалга дуусгахад алдаа гарлаа'
            });  
          }
        }).catch(() => {
          this.$message({
              type: 'info',
              message: 'Захиалга дуусгахыг зогсоолоо'
          });          
        })
      } else {
        console.log('Төлбөрийн дүнгээ зөв оруулна уу')
        alert('Төлбөрийн дүнгээ зөв оруулна уу')
      }
    },
    valueAdd(items, index, limit) {
      if (items[index].item_count == limit) {
          return
      } else {
        items[index].item_count++;
        for (let i = 0; i < items.length; i++) {
          if (i != index && items[i].item_count > 0) {
            items[i].item_count --;
            break;
          }
        }
      }
    },
  }
}
</script>

<style>
.el-message-box {
    width: 90% !important;
}
.el-dialog {
    width: 90%;
}
.el-dialog__header {
  padding: 0px !important;
}
.__payment_type {
  padding-top: 10px;
}
.clearfix {
    display: inline-flex;
}
.order_address {
    font-size: 12px;
    font-family: 'Times New Roman', Times, serif;
}

.order_number {
    font-size: 12px;
    font-family: 'Times New Roman', Times, serif;
}

.order_payment {
    font-size: 12px;
    font-family: 'Times New Roman', Times, serif;
}

.order_action_button {
    width: 100%;
    font-size: 12px;
    font-family: 'Times New Roman', Times, serif;
}
</style>