import { mainRequests } from '@/helpers/custom.js'
// import axios from 'axios'
export default {
  getOutlets (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests.postManager('/driverApp/getOutlets', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getDashboard (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests.postManager('/driverApp/getDashboard', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  }
}